import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MemberProfileInfoStore } from './member-profile-info.store';
import { AppApiConfiguration as apiOf } from 'src/app/configs/app-api.config';
import { catchError, tap } from 'rxjs/operators';
import { ErrorMessage } from 'src/app/core/model/api-error';
import { throwError } from 'rxjs';
import { MemberProfileInfoModel } from '../../models/member-profile-info.model';
import { MemberSkillSetModel } from '../../models/member-info-edit-models/member-skillset.model';
// import { PopupLoadingComponent } from 'src/app/shared/components/popups/popup-loading/popup-loading.component';
// import { PopupErrorComponent } from 'src/app/shared/components/popups/popup-error/popup-error.component';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
// import { PopupSuccessComponent } from 'src/app/shared/components/popups/popup-success/popup-success.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationRoutes } from 'src/app/configs/app-route.config';
import { ToastService } from 'src/app/core/services/toast.service';
// import { UploadedAttachmentModel } from 'src/app/shared/models/uploaded-video.model';

@Injectable({
  providedIn: 'root',
})
export class MemberProfileInfoService {
  private observe!: {
    observe: 'body';
  };

  constructor(
    private http: HttpClient,
    private dialogService: DialogService,
    private profileInfoStore: MemberProfileInfoStore,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastService: ToastService
  ) {}

  async updateState(memberProfileInfo: MemberProfileInfoModel) {
    this.profileInfoStore.update((state) => {
      if(memberProfileInfo.profileUrl){
        memberProfileInfo.profileUrl = memberProfileInfo.profileUrl + '?time=' + new Date().valueOf()
      }
      return {
        ...state,
        profileInfo: { ...state.profileInfo, ...memberProfileInfo },
      };
    });
  }

  sendOtp(body: any) {
    return this.http.post(apiOf.auth.sendOtp, body);
  }

  async getMemberProfile(queryParams?: any) {
    this.profileInfoStore.setLoading(true);
    let url = apiOf.member.profile
    if(queryParams){
      url += queryParams
    }
    try {
      await this.http
        .get(url)
        .pipe(
          tap((data: any) => {
            this.updateState(data);
            this.profileInfoStore.setLoading(false);
          }),
          catchError((apiError: ErrorMessage) => {
            apiError?.errors.map((err) => this.toastService.error(err));
            return throwError(apiError);
          })
        )
        .toPromise();
    } catch (error) {}
    this.profileInfoStore.setLoading(false);
  }

  async updateBasicProfile(profileIntro: any) {
    // delete(profileIntro.countryCode)
    // this.profileInfoStore.setLoading(true);
    try {
      // const dialogRefLoading: DialogRef = this.dialogService
      // .open({
      //   content: PopupLoadingComponent
      // });
      // const loadingInfo = dialogRefLoading.content.instance as PopupLoadingComponent;
      // loadingInfo.writeUp = `Please wait while we are update Profile.`
      await this.http
        .put<MemberProfileInfoModel>(
          apiOf.member.updateBasicProfile,
          { ...profileIntro },
          this.observe
        )
        .pipe(
          tap((data: MemberProfileInfoModel) => {
            this.toastService.success(
              'Congratualtion, your Basic Profle is ready!'
            );
            // setTimeout(() => {
            //   dialogRefLoading.close()
            //   const dialogRefSuccess: DialogRef = this.dialogService
            //   .open({
            //     content: PopupSuccessComponent
            //   });
            //   const successInfo = dialogRefSuccess.content.instance as PopupSuccessComponent;
            //   successInfo.headerTitle = `Congratualtion, your Basic Profle is ready!`
            //   successInfo.description = `Please keep updating Advance section of your profile and keep it fully up to date to get maximum benefit of ecosystem.`
            //   successInfo.buttonText = `Complete your Advance Profile`
            //   successInfo.buttonTextSecond = `Start Learning & Go Back To The Page`
            //   dialogRefSuccess.result.subscribe((data: any) => {
            //     if (data instanceof DialogCloseResult) {
            //     } else {
            //       if(data.isAction){
                    this.router.navigate([ApplicationRoutes.profile.advanceProfileTab]);
                    this.getMemberProfile('?source=profile');
            //       }else{
            //         this.getMemberProfile('?source=profile');
            //         let redirectUrl: string = this.activatedRoute.snapshot.queryParams['redirectUrl'];
            //         if(redirectUrl){
            //           this.router.navigateByUrl(redirectUrl);
            //         }
            //       }
            //     }
            //   });
            //   // this.profileInfoStore.setLoading(false);
            // },1000);
          }),
          catchError((error: ErrorMessage) => {
            error.errors.map((err) => this.toastService.error(err));
            // setTimeout(() => {
            //   dialogRefLoading.close()
              this.profileInfoStore.setError(error);
            //   const dialogRefError: DialogRef = this.dialogService
            //     .open({
            //       content: PopupErrorComponent
            //     });
            //     const ErrorInfo = dialogRefError.content.instance as PopupErrorComponent;
            //     ErrorInfo.headerTitle = error.title
            //     ErrorInfo.errorList = error.errors
            //     ErrorInfo.buttonText = `Go back`
            // },1000)
            return throwError(error);
          })
        )
        .toPromise();
    } catch (error) {}
    this.profileInfoStore.setLoading(false);
  }

  async deletePublications(publicationId: string) {
    this.profileInfoStore.setLoading(true);
    try {
      const requestBody = { publicationId }; 
      // const dialogRefLoading: DialogRef = this.dialogService
      // .open({
      //   content: PopupLoadingComponent
      // });
      // const loadingInfo = dialogRefLoading.content.instance as PopupLoadingComponent;
      // loadingInfo.writeUp = `Please wait while we remove Technical publication / Book.`
      await this.http
        .request('delete', `${apiOf.profile.deletePublications}`, { body: requestBody })
        .pipe(
          tap((data: any) => {
            this.toastService.success(
              'Technical publication / Book has been removed successfully.'
            );
            // setTimeout(() => {
            //   dialogRefLoading.close()
            //   const dialogRefSuccess: DialogRef = this.dialogService
            //   .open({
            //     content: PopupSuccessComponent
            //   });
            //   const successInfo = dialogRefSuccess.content.instance as PopupSuccessComponent;
            //   successInfo.headerTitle = `Technical publication / Book has been removed successfully.`
            //   successInfo.buttonText = `Close`
            //   dialogRefSuccess.result.subscribe((data: any) => {
            //     if (data instanceof DialogCloseResult) {
            //     } else {
            //       if(data.isAction){
                    this.getMemberProfile('?source=profile');
            //       }
            //     }
            //   });
            // }, 1000);
          }),
          catchError((error: ErrorMessage) => {
            error.errors.map((err) => this.toastService.error(err));
            // setTimeout(() => {
            //   dialogRefLoading.close()
              this.profileInfoStore.setError(error);
            //   const dialogRefError: DialogRef = this.dialogService
            //     .open({
            //       content: PopupErrorComponent
            //     });
            //     const ErrorInfo = dialogRefError.content.instance as PopupErrorComponent;
            //     ErrorInfo.headerTitle = error.title
            //     ErrorInfo.errorList = error.errors
            //     ErrorInfo.buttonText = `Go back`
            // },1000)
            return throwError(error);
          })
        )
        .toPromise();
    } catch (error) {
      // Handle any synchronous errors
    } finally {
      this.profileInfoStore.setLoading(false);
    }
  }

  async deletePatents(patentId: string) {
    this.profileInfoStore.setLoading(true);
    try {
      // const dialogRefLoading: DialogRef = this.dialogService
      // .open({
      //   content: PopupLoadingComponent
      // });
      // const loadingInfo = dialogRefLoading.content.instance as PopupLoadingComponent;
      // loadingInfo.writeUp = `Please wait while we remove patent.`
      await this.http
        .delete(`${apiOf.profile.deletePatents}`, { body: {"patentId": patentId} })
        .pipe(
          tap((data: any) => {
            this.toastService.success(
              'Patent has been removed successfully.'
            );
            // setTimeout(() => {
            //   dialogRefLoading.close()
            //   const dialogRefSuccess: DialogRef = this.dialogService
            //   .open({
            //     content: PopupSuccessComponent
            //   });
            //   const successInfo = dialogRefSuccess.content.instance as PopupSuccessComponent;
            //   successInfo.headerTitle = `Patent has been removed successfully.`
            //   successInfo.buttonText = `Close`
            //   dialogRefSuccess.result.subscribe((data: any) => {
            //     if (data instanceof DialogCloseResult) {
            //     } else {
            //       if(data.isAction){
                    this.getMemberProfile('?source=profile');
            //       }
            //     }
            //   });
            // }, 1000);
          }),
          catchError((error: ErrorMessage) => {
            error.errors.map((err) => this.toastService.error(err));
            // setTimeout(() => {
            //   dialogRefLoading.close()
              this.profileInfoStore.setError(error);
            //   const dialogRefError: DialogRef = this.dialogService
            //     .open({
            //       content: PopupErrorComponent
            //     });
            //     const ErrorInfo = dialogRefError.content.instance as PopupErrorComponent;
            //     ErrorInfo.headerTitle = error.title
            //     ErrorInfo.errorList = error.errors
            //     ErrorInfo.buttonText = `Go back`
            // },1000)
            return throwError(error);
          })
        )
        .toPromise();
    } catch (error) {
      // Handle any synchronous errors
    } finally {
      this.profileInfoStore.setLoading(false);
    }
  }

  async deleteMemberCommitte(membershipId: string) {
    this.profileInfoStore.setLoading(true);
    try {
      const requestBody = { membershipId }; 
      // const dialogRefLoading: DialogRef = this.dialogService
      // .open({
      //   content: PopupLoadingComponent
      // });
      // const loadingInfo = dialogRefLoading.content.instance as PopupLoadingComponent;
      // loadingInfo.writeUp = `Please wait while we remove membership in committe.`
      await this.http
        .request('delete', `${apiOf.profile.deleteMembership}`, { body: requestBody })
        .pipe(
          tap((data: any) => {
            this.toastService.success(
              'Membership in committe has been removed successfully.'
            );
            // setTimeout(() => {
            //   dialogRefLoading.close()
            //   const dialogRefSuccess: DialogRef = this.dialogService
            //   .open({
            //     content: PopupSuccessComponent
            //   });
            //   const successInfo = dialogRefSuccess.content.instance as PopupSuccessComponent;
            //   successInfo.headerTitle = `Membership in committe has been removed successfully.`
            //   successInfo.buttonText = `Close`
            //   dialogRefSuccess.result.subscribe((data: any) => {
            //     if (data instanceof DialogCloseResult) {
            //     } else {
            //       if(data.isAction){
                    this.getMemberProfile('?source=profile');
            //       }
            //     }
            //   });
            // }, 1000);
          }),
          catchError((error: ErrorMessage) => {
            error.errors.map((err) => this.toastService.error(err));
            // setTimeout(() => {
            //   dialogRefLoading.close()
              this.profileInfoStore.setError(error);
            //   const dialogRefError: DialogRef = this.dialogService
            //     .open({
            //       content: PopupErrorComponent
            //     });
            //     const ErrorInfo = dialogRefError.content.instance as PopupErrorComponent;
            //     ErrorInfo.headerTitle = error.title
            //     ErrorInfo.errorList = error.errors
            //     ErrorInfo.buttonText = `Go back`
            // },1000)            
            return throwError(error);
          })
        )
        .toPromise();
    } catch (error) {
      // Handle any synchronous errors
    } finally {
      this.profileInfoStore.setLoading(false);
    }
  }

  async updateProfilePublication(profileIntro: any) {
    // delete(profileIntro.countryCode)
    this.profileInfoStore.setLoading(true);
    try {
      // const dialogRefLoading: DialogRef = this.dialogService
      // .open({
      //   content: PopupLoadingComponent
      // });
      // const loadingInfo = dialogRefLoading.content.instance as PopupLoadingComponent;
      // loadingInfo.writeUp = `Please wait while we are add technical publications / books.`
      await this.http
        .post<any>(
          apiOf.profile.publication,
          { ...profileIntro },
          this.observe
        )
        .pipe(
          tap((data: any) => {
            this.toastService.success(
              'Congratualtion, your action is completed!'
            );
            // setTimeout(() => {
            //   dialogRefLoading.close()
            //   const dialogRefSuccess: DialogRef = this.dialogService
            //   .open({
            //     content: PopupSuccessComponent
            //   });
            //   const successInfo = dialogRefSuccess.content.instance as PopupSuccessComponent;
            //   successInfo.headerTitle = `Congratualtion, your action is completed!`
            //   successInfo.description = `Please keep updating other section of your profile and keep it fully up to date to get maximum benefit of ecosystem.`
            //   successInfo.buttonText = `Complete your Profile`
            //   dialogRefSuccess.result.subscribe((data: any) => {
            //     if (data instanceof DialogCloseResult) {
            //     } else {
            //       if(data.isAction){
                    this.getMemberProfile('?source=profile');
            //       }
            //     }
            //   });
            //   // this.profileInfoStore.setLoading(false);
            // },1000);          
          }),
          catchError((error: ErrorMessage) => {
            error.errors.map((err) => this.toastService.error(err));
            // setTimeout(() => {
            //   dialogRefLoading.close()
              this.profileInfoStore.setError(error);
            //   const dialogRefError: DialogRef = this.dialogService
            //     .open({
            //       content: PopupErrorComponent
            //     });
            //     const ErrorInfo = dialogRefError.content.instance as PopupErrorComponent;
            //     ErrorInfo.headerTitle = error.title
            //     ErrorInfo.errorList = error.errors
            //     ErrorInfo.buttonText = `Go back`
            // },1000)
            return throwError(error);
          })
        )
        .toPromise();
    } catch (error) {}
    this.profileInfoStore.setLoading(false);
  }

  async updateProfilePatent(profileIntro: any) {
    // delete(profileIntro.countryCode) 
    this.profileInfoStore.setLoading(true);
    try {
      // const dialogRefLoading: DialogRef = this.dialogService
      // .open({
      //   content: PopupLoadingComponent
      // });
      // const loadingInfo = dialogRefLoading.content.instance as PopupLoadingComponent;
      // loadingInfo.writeUp = `Please wait while we are add patent.`
      await this.http
        .post<any>(
          apiOf.profile.patents,
          { ...profileIntro },
          this.observe
        )
        .pipe(
          tap((data: any) => {
            this.toastService.success(
              'Congratualtion, your action is completed!'
            );
            // setTimeout(() => {
            //   dialogRefLoading.close()
            //   const dialogRefSuccess: DialogRef = this.dialogService
            //   .open({
            //     content: PopupSuccessComponent
            //   });
            //   const successInfo = dialogRefSuccess.content.instance as PopupSuccessComponent;
            //   successInfo.headerTitle = `Congratualtion, your action is completed!`
            //   successInfo.description = `Please keep updating other section of your profile and keep it fully up to date to get maximum benefit of ecosystem.`
            //   successInfo.buttonText = `Complete your Profile`
            //   dialogRefSuccess.result.subscribe((data: any) => {
            //     if (data instanceof DialogCloseResult) {
            //     } else {
            //       if(data.isAction){
                    this.getMemberProfile('?source=profile');
            //       }
            //     }
            //   });
            //   // this.profileInfoStore.setLoading(false);
            // },1000); 
          }),
          catchError((error: ErrorMessage) => {
            error.errors.map((err) => this.toastService.error(err));
            // setTimeout(() => {
            //   dialogRefLoading.close()
              this.profileInfoStore.setError(error);
            //   const dialogRefError: DialogRef = this.dialogService
            //     .open({
            //       content: PopupErrorComponent
            //     });
            //     const ErrorInfo = dialogRefError.content.instance as PopupErrorComponent;
            //     ErrorInfo.headerTitle = error.title
            //     ErrorInfo.errorList = error.errors
            //     ErrorInfo.buttonText = `Go back`
            // },1000)            
            return throwError(error);
          })
        )
        .toPromise();
    } catch (error) {}
    this.profileInfoStore.setLoading(false);
  }

  async updateProfileMembership(profileIntro: any) {
    // delete(profileIntro.countryCode)
    this.profileInfoStore.setLoading(true);
    try {
      // const dialogRefLoading: DialogRef = this.dialogService
      // .open({
      //   content: PopupLoadingComponent
      // });
      // const loadingInfo = dialogRefLoading.content.instance as PopupLoadingComponent;
      // loadingInfo.writeUp = `Please wait while we are add membership in committe.`
      await this.http
        .post<any>(
          apiOf.profile.membership,
          { ...profileIntro },
          this.observe
        )
        .pipe(
          tap((data: any) => {
            this.toastService.success(
              'Congratualtion, your action is completed!'
            );
            // setTimeout(() => {
            //   dialogRefLoading.close()
            //   const dialogRefSuccess: DialogRef = this.dialogService
            //   .open({
            //     content: PopupSuccessComponent
            //   });
            //   const successInfo = dialogRefSuccess.content.instance as PopupSuccessComponent;
            //   successInfo.headerTitle = `Congratualtion, your action is completed!`
            //   successInfo.description = `Please keep updating other section of your profile and keep it fully up to date to get maximum benefit of ecosystem.`
            //   successInfo.buttonText = `Complete your Profile`
            //   dialogRefSuccess.result.subscribe((data: any) => {
            //     if (data instanceof DialogCloseResult) {
            //     } else {
            //       if(data.isAction){
                    this.getMemberProfile('?source=profile');
            //       }
            //     }
            //   });
            //   // this.profileInfoStore.setLoading(false);
            // },1000); 
          }),
          catchError((error: ErrorMessage) => {
            error.errors.map((err) => this.toastService.error(err));
            // setTimeout(() => {
            //   dialogRefLoading.close()
              this.profileInfoStore.setError(error);
            //   const dialogRefError: DialogRef = this.dialogService
            //     .open({
            //       content: PopupErrorComponent
            //     });
            //     const ErrorInfo = dialogRefError.content.instance as PopupErrorComponent;
            //     ErrorInfo.headerTitle = error.title
            //     ErrorInfo.errorList = error.errors
            //     ErrorInfo.buttonText = `Go back`
            // },1000)    
            return throwError(error);
          })
        )
        .toPromise();
    } catch (error) {}
    this.profileInfoStore.setLoading(false);
  }

  async updateBasicInfoProfile(profileIntro: any) {
    // this.profileInfoStore.setLoading(true);
    try {
      // const dialogRefLoading: DialogRef = this.dialogService
      // .open({
      //   content: PopupLoadingComponent
      // });
      // const loadingInfo = dialogRefLoading.content.instance as PopupLoadingComponent;
      // loadingInfo.writeUp = `Please wait while we are update Profile.`
      await this.http
        .put<any>(
          apiOf.member.updateBasicProfileInfo,
          { ...profileIntro },
          this.observe
        )
        .pipe(
          tap((data: any) => {
            this.toastService.success(
              'Congratualtion, your phone number is updated!'
            );
            // setTimeout(() => {
            //   dialogRefLoading.close()
            //   const dialogRefSuccess: DialogRef = this.dialogService
            //   .open({
            //     content: PopupSuccessComponent
            //   });
            //   const successInfo = dialogRefSuccess.content.instance as PopupSuccessComponent;
            //   successInfo.headerTitle = `Congratualtion, your phone number is updated!`
            //   successInfo.description = `Please keep updating other section of your basic profile and keep it fully up to date to get maximum benefit of ecosystem.`
            //   successInfo.buttonText = `Complete your Profile`
            //   dialogRefSuccess.result.subscribe((data: any) => {
            //     if (data instanceof DialogCloseResult) {
            //     } else {
            //       if(data.isAction){
                    this.getMemberProfile('?source=profile');
            //       }
            //     }
            //   });
            //   // this.profileInfoStore.setLoading(false);
            // },1000);
          }),
          catchError((error: ErrorMessage) => {
            error.errors.map((err) => this.toastService.error(err));
            // setTimeout(() => {
            //   dialogRefLoading.close()
              this.profileInfoStore.setError(error);
            //   const dialogRefError: DialogRef = this.dialogService
            //     .open({
            //       content: PopupErrorComponent
            //     });
            //     const ErrorInfo = dialogRefError.content.instance as PopupErrorComponent;
            //     ErrorInfo.headerTitle = error.title
            //     ErrorInfo.errorList = error.errors
            //     ErrorInfo.buttonText = `Go back`
            // },1000)
            return throwError(error);
          })
        )
        .toPromise();
    } catch (error) {}
    this.profileInfoStore.setLoading(false);
  }

  async updateProfileLearn(profileIntro: any) {
    this.profileInfoStore.setLoading(true);
    try {
      // const dialogRefLoading: DialogRef = this.dialogService
      // .open({
      //   content: PopupLoadingComponent
      // });
      // const loadingInfo = dialogRefLoading.content.instance as PopupLoadingComponent;
      // loadingInfo.writeUp = `Please wait while we are update Profile.`
      await this.http
        .put<any>(
          apiOf.member.updateProfileAreaOfInterest,
          { ...profileIntro },
          this.observe
        )
        .pipe(
          tap((data: any) => {
            this.toastService.success(
              'Congratualtion, your Learn Profle is ready!'
            );
            // setTimeout(() => {
            //   dialogRefLoading.close()
            //   const dialogRefSuccess: DialogRef = this.dialogService
            //   .open({
            //     content: PopupSuccessComponent
            //   });
            //   const successInfo = dialogRefSuccess.content.instance as PopupSuccessComponent;
            //   successInfo.headerTitle = `Congratualtion, your Learn Profle is ready!`
            //   successInfo.description = `Please keep updating other section of your profile and keep it fully up to date to get maximum benefit of ecosystem.`
            //   successInfo.buttonTextSecond = `Explore More`
            //   successInfo.buttonText = `Post Content`
            //   dialogRefSuccess.result.subscribe((data: any) => {
            //     if (data instanceof DialogCloseResult) {
            //     } else {
            //       if(data.isAction){
                    this.getMemberProfile('?source=profile');
            //         this.router.navigate([ApplicationRoutes.comman_form.learn.create])
            //       }else{
            //         this.router.navigate([ApplicationRoutes.search.home])
            //       }
            //     }
            //   });        
            // },1000);  
          }),
          catchError((error: ErrorMessage) => {
            error.errors.map((err) => this.toastService.error(err));
            // setTimeout(() => {
            //   dialogRefLoading.close()
              this.profileInfoStore.setError(error);
            //   const dialogRefError: DialogRef = this.dialogService
            //     .open({
            //       content: PopupErrorComponent
            //     });
            //     const ErrorInfo = dialogRefError.content.instance as PopupErrorComponent;
            //     ErrorInfo.headerTitle = error.title
            //     ErrorInfo.errorList = error.errors
            //     ErrorInfo.buttonText = `Go back`
            // },1000)            
            return throwError(error);
          })
        )
        .toPromise();
    } catch (error) {}
    this.profileInfoStore.setLoading(false);
  }
  
  async updateProfileIdentity(profileIntro: any) {
    this.profileInfoStore.setLoading(true);
    try {
      // const dialogRefLoading: DialogRef = this.dialogService
      // .open({
      //   content: PopupLoadingComponent
      // });
      // const loadingInfo = dialogRefLoading.content.instance as PopupLoadingComponent;
      // loadingInfo.writeUp = `Please wait while we are update Profile.`
      await this.http
        .put<any>(
          apiOf.member.updateProfileIdentity,
          { ...profileIntro },
          this.observe
        )
        .pipe(
          tap((data: any) => {
            this.toastService.success(
              'Congratualtion, your action is completed!'
            );
            // setTimeout(() => {
            //   dialogRefLoading.close()
            //   const dialogRefSuccess: DialogRef = this.dialogService
            //   .open({
            //     content: PopupSuccessComponent
            //   });
            //   const successInfo = dialogRefSuccess.content.instance as PopupSuccessComponent;
            //   successInfo.headerTitle = `Congratualtion, your action is completed!`
            //   successInfo.description = `Please keep updating other section of your profile and keep it fully up to date to get maximum benefit of ecosystem.`
            //   successInfo.buttonText = `Complete your Profile`
            //   dialogRefSuccess.result.subscribe((data: any) => {
            //     if (data instanceof DialogCloseResult) {
            //     } else {
            //       if(data.isAction){
                    this.getMemberProfile('?source=profile');
            //       }
            //     }
            //   });        
            // },1000); 
          }),
          catchError((error: ErrorMessage) => {
            error.errors.map((err) => this.toastService.error(err));
            // setTimeout(() => {
            //   dialogRefLoading.close()
              this.profileInfoStore.setError(error);
            //   const dialogRefError: DialogRef = this.dialogService
            //     .open({
            //       content: PopupErrorComponent
            //     });
            //     const ErrorInfo = dialogRefError.content.instance as PopupErrorComponent;
            //     ErrorInfo.headerTitle = error.title
            //     ErrorInfo.errorList = error.errors
            //     ErrorInfo.buttonText = `Go back`
            // },1000)            
            return throwError(error);
          })
        )
        .toPromise();
    } catch (error) {}
    this.profileInfoStore.setLoading(false);
  }

  async updateProfileIntro(profileIntro: any) {
    this.profileInfoStore.setLoading(true);
    try {
      // const dialogRefLoading: DialogRef = this.dialogService
      // .open({
      //   content: PopupLoadingComponent
      // });
      // const loadingInfo = dialogRefLoading.content.instance as PopupLoadingComponent;
      // loadingInfo.writeUp = `Please wait while we are update Profile.`
      await this.http
        .put<any>(
          apiOf.member.updateBasicProfileAdvanced,
          { ...profileIntro },
          this.observe
        )
        .pipe(
          tap((data: any) => {
            this.toastService.success(
              'Congratualtion, your action is completed!'
            );
            // setTimeout(() => {
            //   dialogRefLoading.close()
            //   const dialogRefSuccess: DialogRef = this.dialogService
            //   .open({
            //     content: PopupSuccessComponent
            //   });
            //   const successInfo = dialogRefSuccess.content.instance as PopupSuccessComponent;
            //   successInfo.headerTitle = `Congratualtion, your action is completed!`
            //   successInfo.description = `Please keep updating other section of your profile and keep it fully up to date to get maximum benefit of ecosystem.`
            //   successInfo.buttonText = `Complete your Profile`
            //   dialogRefSuccess.result.subscribe((data: any) => {
            //     if (data instanceof DialogCloseResult) {
            //     } else {
            //       if(data.isAction){
                    this.getMemberProfile('?source=profile');
            //       }
            //     }
            //   });        
            // },1000);  
          }),
          catchError((error: ErrorMessage) => {
            error.errors.map((err) => this.toastService.error(err));
            // setTimeout(() => {
            //   dialogRefLoading.close()
              this.profileInfoStore.setError(error);
            //   const dialogRefError: DialogRef = this.dialogService
            //     .open({
            //       content: PopupErrorComponent
            //     });
            //     const ErrorInfo = dialogRefError.content.instance as PopupErrorComponent;
            //     ErrorInfo.headerTitle = error.title
            //     ErrorInfo.errorList = error.errors
            //     ErrorInfo.buttonText = `Go back`
            // },1000);
            return throwError(error);
          })
        )
        .toPromise();
    } catch (error) {}
    this.profileInfoStore.setLoading(false);
  }

  async updateCompanyProfileIntro(profileIntro: any) {
    this.profileInfoStore.setLoading(true);
    try {
      // const dialogRefLoading: DialogRef = this.dialogService
      // .open({
      //   content: PopupLoadingComponent
      // });
      // const loadingInfo = dialogRefLoading.content.instance as PopupLoadingComponent;
      // loadingInfo.writeUp = `Please wait while we are update Profile.`
      await this.http
        .post<any>(
          apiOf.member.updateCompanyProfileInfo,
          { ...profileIntro },
          this.observe
        )
        .pipe(
          tap((data: any) => {
            this.toastService.success(
              'Congratualtion, your Advance Profile is ready!'
            );
            // setTimeout(() => {
            //   dialogRefLoading.close()
            //   const dialogRefSuccess: DialogRef = this.dialogService
            //   .open({
            //     content: PopupSuccessComponent
            //   });
            //   const successInfo = dialogRefSuccess.content.instance as PopupSuccessComponent;
            //   successInfo.headerTitle = `Congratualtion, your Advance Profile is ready!`
            //   successInfo.description = `Please keep updating other section of your profile and keep it fully up to date to get maximum benefit of ecosystem.`
            //   successInfo.buttonText = `Complete your Profile`
            //   dialogRefSuccess.result.subscribe((data: any) => {
            //     if (data instanceof DialogCloseResult) {
            //     } else {
            //       if(data.isAction){
                    this.getMemberProfile('?source=profile');
            //       }
            //     }
            //   });        
            // },1000);  
          }),
          catchError((error: ErrorMessage) => {
            error.errors.map((err) => this.toastService.error(err));
            // setTimeout(() => {
            //   dialogRefLoading.close()
              this.profileInfoStore.setError(error);
            //   const dialogRefError: DialogRef = this.dialogService
            //     .open({
            //       content: PopupErrorComponent
            //     });
            //     const ErrorInfo = dialogRefError.content.instance as PopupErrorComponent;
            //     ErrorInfo.headerTitle = error.title
            //     ErrorInfo.errorList = error.errors
            //     ErrorInfo.buttonText = `Go back`
            // },1000);
            return throwError(error);
          })
        )
        .toPromise();
    } catch (error) {}
    this.profileInfoStore.setLoading(false);
  }

  async updateContectInfo(profileIntro: any) {
    this.profileInfoStore.setLoading(true);
    try {
      // const dialogRefLoading: DialogRef = this.dialogService
      // .open({
      //   content: PopupLoadingComponent
      // });
      // const loadingInfo = dialogRefLoading.content.instance as PopupLoadingComponent;
      // loadingInfo.writeUp = `Please wait while we are update Profile.`
      await this.http
        .post<any>(
          apiOf.member.updateContactInfo,
          { ...profileIntro },
          this.observe
        )
        .pipe(
          tap((data: any) => {
            this.toastService.success(
              'Congratualtion, your action is completed!'
            );
            // setTimeout(() => {
            //   dialogRefLoading.close()
            //   const dialogRefSuccess: DialogRef = this.dialogService
            //   .open({
            //     content: PopupSuccessComponent
            //   });
            //   const successInfo = dialogRefSuccess.content.instance as PopupSuccessComponent;
            //   successInfo.headerTitle = `Congratualtion, your action is completed!`
            //   successInfo.description = `Please keep updating other section of your profile and keep it fully up to date to get maximum benefit of ecosystem.`
            //   successInfo.buttonText = `Complete your Profile`
            //   dialogRefSuccess.result.subscribe((data: any) => {
            //     if (data instanceof DialogCloseResult) {
            //     } else {
            //       if(data.isAction){
                    this.getMemberProfile('?source=profile');
            //       }
            //     }
            //   });        
            // },1000);
          }),
          catchError((error: ErrorMessage) => {
            error.errors.map((err) => this.toastService.error(err));
            // setTimeout(() => {
            //   dialogRefLoading.close()
              this.profileInfoStore.setError(error);
            //   const dialogRefError: DialogRef = this.dialogService
            //     .open({
            //       content: PopupErrorComponent
            //     });
            //     const ErrorInfo = dialogRefError.content.instance as PopupErrorComponent;
            //     ErrorInfo.headerTitle = error.title
            //     ErrorInfo.errorList = error.errors
            //     ErrorInfo.buttonText = `Go back`
            // },1000);            
            return throwError(error);
          })
        )
        .toPromise();
    } catch (error) {}
    this.profileInfoStore.setLoading(false);
  }

  async updateCompanyContactInfo(profileIntro: any) {
    this.profileInfoStore.setLoading(true);
    try {
      // const dialogRefLoading: DialogRef = this.dialogService
      // .open({
      //   content: PopupLoadingComponent
      // });
      // const loadingInfo = dialogRefLoading.content.instance as PopupLoadingComponent;
      // loadingInfo.writeUp = `Please wait while we are update Profile.`
      await this.http
        .post<any>(
          apiOf.member.updateCompanyContactInfo,
          { ...profileIntro },
          this.observe
        )
        .pipe(
          tap((data: any) => {
            this.toastService.success(
              'Congratualtion, your action is completed!'
            );
            // setTimeout(() => {
            //   dialogRefLoading.close()
            //   const dialogRefSuccess: DialogRef = this.dialogService
            //   .open({
            //     content: PopupSuccessComponent
            //   });
            //   const successInfo = dialogRefSuccess.content.instance as PopupSuccessComponent;
            //   successInfo.headerTitle = `Congratualtion, your action is completed!`
            //   successInfo.description = `Please keep updating other section of your profile and keep it fully up to date to get maximum benefit of ecosystem.`
            //   successInfo.buttonText = `Complete your Profile`
            //   dialogRefSuccess.result.subscribe((data: any) => {
            //     if (data instanceof DialogCloseResult) {
            //     } else {
            //       if(data.isAction){
                    this.getMemberProfile('?source=profile');
            //       }
            //     }
            //   });        
            // },1000);
          }),
          catchError((error: ErrorMessage) => {
            error.errors.map((err) => this.toastService.error(err));
            // setTimeout(() => {
            //   dialogRefLoading.close()
              this.profileInfoStore.setError(error);
            //   const dialogRefError: DialogRef = this.dialogService
            //     .open({
            //       content: PopupErrorComponent
            //     });
            //     const ErrorInfo = dialogRefError.content.instance as PopupErrorComponent;
            //     ErrorInfo.headerTitle = error.title
            //     ErrorInfo.errorList = error.errors
            //     ErrorInfo.buttonText = `Go back`
            // },1000);
            return throwError(error);
          })
        )
        .toPromise();
    } catch (error) {}
    this.profileInfoStore.setLoading(false);
  }

  // async updateProfileBio(profileBio: MemberBioRequestModel) {
  //   this.profileInfoStore.setLoading(true);
  //   try {
  //     await this.http
  //       .put<any>(
  //         apiOf.member.updateProfileBio,
  //         { ...profileBio },
  //         this.observe
  //       )
  //       .pipe(
  //         tap((data) => {
  //           // this.toastService.success(
  //           //   'Profile bio-data has been successfully updated !!'
  //           // );
  //           this.getMemberProfile('?source=profile');
  //           this.profileInfoStore.setLoading(false);
  //         }),
  //         catchError((error: ErrorMessage) => {
  //           // error?.errors.map((err) => this.toastService.error(err));
  //           this.profileInfoStore.setError(error);
  //           return throwError(error);
  //         })
  //       )
  //       .toPromise();
  //   } catch (error) {}
  //   this.profileInfoStore.setLoading(false);
  // }

  // async updateProfileContacts(profileContacts: MemberContactRequestModel[]) {
  //   this.profileInfoStore.setLoading(true);
  //   try {
  //     await this.http
  //       .put<any>(
  //         apiOf.member.updateProfileContacts,
  //         profileContacts,
  //         this.observe
  //       )
  //       .pipe(
  //         tap((data) => {
  //           // this.toastService.success(
  //           //   'Profile contact-info has been successfully updated !!'
  //           // );
  //           this.getMemberProfile('?source=profile');
  //           this.profileInfoStore.setLoading(false);
  //         }),
  //         catchError((error: ErrorMessage) => {
  //           // error?.errors.map((err) => this.toastService.error(err));
  //           this.profileInfoStore.setError(error);
  //           return throwError(error);
  //         })
  //       )
  //       .toPromise();
  //   } catch (error) {}
  //   this.profileInfoStore.setLoading(false);
  // }

  async updateProfileSkillSet(profileSkillSet: MemberSkillSetModel) {
    this.profileInfoStore.setLoading(true);
    try {
      // const dialogRefLoading: DialogRef = this.dialogService
      // .open({
      //   content: PopupLoadingComponent
      // });
      // const loadingInfo = dialogRefLoading.content.instance as PopupLoadingComponent;
      // loadingInfo.writeUp = `Please wait while we are update profile.`
      await this.http
        .post<any>(
          apiOf.member.updateProfileSkillSet,
          { ...profileSkillSet },
          this.observe
        )
        .pipe(
          tap((data) => {
            this.toastService.success(
              'Profile disciplines, domains and skills has been successfully updated !!'
            );
            // setTimeout(() => {
            //   dialogRefLoading.close()
            //   const dialogRefSuccess: DialogRef = this.dialogService
            //   .open({
            //     content: PopupSuccessComponent
            //   });
            //   const successInfo = dialogRefSuccess.content.instance as PopupSuccessComponent;
            //   successInfo.headerTitle = `Congratualtion, your Earn Profile is ready!`
            //   successInfo.description = `Please keep updating other section of your profile and keep it fully up to date to get maximum benefit of ecosystem.`
            //   successInfo.buttonText = `Complete your Profile`
            //   dialogRefSuccess.result.subscribe((data: any) => {
            //     if (data instanceof DialogCloseResult) {
            //     } else {
            //       if(data.isAction){
                    this.getMemberProfile('?source=profile');
            //       }
            //     }
            //   });
            //   // this.profileInfoStore.setLoading(false);
            // },1000);
            // this.getMemberProfile('?source=profile');
            this.profileInfoStore.setLoading(false);
          }),
          catchError((error: ErrorMessage) => {
            error?.errors.map((err) => this.toastService.error(err));
            // setTimeout(() => {
            //   dialogRefLoading.close()
              this.profileInfoStore.setError(error);
            //   const dialogRefError: DialogRef = this.dialogService
            //     .open({
            //       content: PopupErrorComponent
            //     });
            //     const ErrorInfo = dialogRefError.content.instance as PopupErrorComponent;
            //     ErrorInfo.headerTitle = error.title
            //     ErrorInfo.errorList = error.errors
            //     ErrorInfo.buttonText = `Go back`
            // },1000)            
            return throwError(error);
          })
        )
        .toPromise();
    } catch (error) {}
    this.profileInfoStore.setLoading(false);
  }

  async uploadCaseStudies(caseStudies: FormData) {
    this.profileInfoStore.setLoading(true);
    try {
      await this.http
        .put<any>(apiOf.member.saveCaseStudies, caseStudies, this.observe)
        .pipe(
          tap((data) => {
            this.toastService.success(
              'Profile case-studies has been successfully updated !!'
            );
            this.getMemberProfile('?source=profile');
            this.profileInfoStore.setLoading(false);
          }),
          catchError((error: ErrorMessage) => {
            error?.errors.map((err) => this.toastService.error(err));
            this.profileInfoStore.setError(error);
            return throwError(error);
          })
        )
        .toPromise();
    } catch (error) {}
    this.profileInfoStore.setLoading(false);
  }

  async signNda() {
    this.profileInfoStore.setLoading(true);
    try {
      // const dialogRefLoading: DialogRef = this.dialogService
      // .open({
      //   content: PopupLoadingComponent
      // });
      // const loadingInfo = dialogRefLoading.content.instance as PopupLoadingComponent;
      // loadingInfo.writeUp = `Please wait while we are update Nda Status.`
      await this.http
        .post<any>(apiOf.member.confirmNda, null, this.observe)
        .pipe(
          tap((data) => {
            this.toastService.success('You have successfully Signed NDA!!');
            // setTimeout(() => {
            //   dialogRefLoading.close()
            //   const dialogRefSuccess: DialogRef = this.dialogService
            //   .open({
            //     content: PopupSuccessComponent
            //   });
            //   const successInfo = dialogRefSuccess.content.instance as PopupSuccessComponent;
            //   successInfo.headerTitle = `Congratualtion, NDA is signed!`
            //   successInfo.description = `Please keep updating other section of your profile and keep it fully up to date to get maximum benefit of ecosystem.`
            //   successInfo.buttonText = `Complete your Profile`
            //   dialogRefSuccess.result.subscribe((data: any) => {
            //     if (data instanceof DialogCloseResult) {
            //     } else {
            //       if(data.isAction){
                    this.getMemberProfile('?source=profile');
            //       }
            //     }
            //   });
            //   // this.profileInfoStore.setLoading(false);
            // },1000);
          }),
          catchError((error: ErrorMessage) => {
            error?.errors.map((err) => this.toastService.error(err));
            // setTimeout(() => {
            //   dialogRefLoading.close()
              this.profileInfoStore.setError(error);
            //   const dialogRefError: DialogRef = this.dialogService
            //     .open({
            //       content: PopupErrorComponent
            //     });
            //     const ErrorInfo = dialogRefError.content.instance as PopupErrorComponent;
            //     ErrorInfo.headerTitle = error.title
            //     ErrorInfo.errorList = error.errors
            //     ErrorInfo.buttonText = `Go back`
            // },1000)
            return throwError(error);
          })
        )
        .toPromise();
    } catch (error) {}
    this.profileInfoStore.setLoading(false);
  }

  async updateProfilePicture(imageFile: any) {
    // this.profileInfoStore.setLoading(true);
    const formData = new FormData();
    formData.append('UploadedFile', imageFile);

    const url = apiOf.member.profileImage
    try {
      // const dialogRefLoading: DialogRef = this.dialogService
      // .open({
      //   content: PopupLoadingComponent
      // });
      // const loadingInfo = dialogRefLoading.content.instance as PopupLoadingComponent;
      // loadingInfo.writeUp = `Please wait while we are update Profile Image.`
      await this.http
        .post<any>(url, formData, this.observe)
        .pipe(
          tap(() => {
            //take to congratulations page
            this.toastService.success(
              'Profile image has been successfully updated !!'
            );
            // setTimeout(() => {
            //   dialogRefLoading.close()
            //   const dialogRefSuccess: DialogRef = this.dialogService
            //   .open({
            //     content: PopupSuccessComponent
            //   });
            //   const successInfo = dialogRefSuccess.content.instance as PopupSuccessComponent;
            //   successInfo.headerTitle = `Congratualtion, your action is completed!`
            //   successInfo.description = `Please keep updating other section of your profile and keep it fully up to date to get maximum benefit of ecosystem.`
            //   successInfo.buttonText = `Complete your Profile`
            //   dialogRefSuccess.result.subscribe((data: any) => {
            //     if (data instanceof DialogCloseResult) {
            //     } else {
            //       if(data.isAction){
                    this.getMemberProfile('?source=profile');
            //       }
            //     }
            //   });
            //   // this.profileInfoStore.setLoading(false);
            // },1000);
          }),
          catchError((error: ErrorMessage) => {
            error?.errors.map((err) => this.toastService.error(err));
            // setTimeout(() => {
            //   dialogRefLoading.close()
              this.profileInfoStore.setError(error);
            //   const dialogRefError: DialogRef = this.dialogService
            //     .open({
            //       content: PopupErrorComponent
            //     });
            //     const ErrorInfo = dialogRefError.content.instance as PopupErrorComponent;
            //     ErrorInfo.headerTitle = error.title
            //     ErrorInfo.errorList = error.errors
            //     ErrorInfo.buttonText = `Go back`
            // },1000)
            return throwError(error);
          })
        )
        .toPromise();
    } catch (error) {}
    this.profileInfoStore.setLoading(false);
  }
  async updateProfilePictureCompany(imageFile: any) {
    // this.profileInfoStore.setLoading(true);

    const formData = new FormData();
    formData.append('UploadedFile', imageFile);

    const url = apiOf.member.profileImageCompany
    try {
      // const dialogRefLoading: DialogRef = this.dialogService
      // .open({
      //   content: PopupLoadingComponent
      // });
      // const loadingInfo = dialogRefLoading.content.instance as PopupLoadingComponent;
      // loadingInfo.writeUp = `Please wait while we are update company logo.`
      await this.http
        .post<any>(url, formData, this.observe)
        .pipe(
          tap(() => {
            //take to congratulations page
            this.toastService.success(
              'Company Profile image has been successfully updated !!'
            );
            // setTimeout(() => {
            //   dialogRefLoading.close()
            //   const dialogRefSuccess: DialogRef = this.dialogService
            //   .open({
            //     content: PopupSuccessComponent
            //   });
            //   const successInfo = dialogRefSuccess.content.instance as PopupSuccessComponent;
            //   successInfo.headerTitle = `Congratualtion, your action is completed!`
            //   successInfo.description = `Please keep updating other section of your profile and keep it fully up to date to get maximum benefit of ecosystem.`
            //   successInfo.buttonText = `Complete your Profile`
            //   dialogRefSuccess.result.subscribe((data: any) => {
            //     if (data instanceof DialogCloseResult) {
            //     } else {
            //       if(data.isAction){
                    this.getMemberProfile('?source=profile');
            //       }
            //     }
            //   });
            //   // this.profileInfoStore.setLoading(false);
            // },1000);
          }),
          catchError((error: ErrorMessage) => {
            error?.errors.map((err) => this.toastService.error(err));
            // setTimeout(() => {
            //   dialogRefLoading.close()
              this.profileInfoStore.setError(error);
            //   const dialogRefError: DialogRef = this.dialogService
            //     .open({
            //       content: PopupErrorComponent
            //     });
            //     const ErrorInfo = dialogRefError.content.instance as PopupErrorComponent;
            //     ErrorInfo.headerTitle = error.title
            //     ErrorInfo.errorList = error.errors
            //     ErrorInfo.buttonText = `Go back`
            // },1000)
            return throwError(error);
          })
        )
        .toPromise();
    } catch (error) {}
    this.profileInfoStore.setLoading(false);
  }
  

}
