import { ActivatedRoute, Router } from '@angular/router';
import {
  CaptureCompanyResponse,
  CaptureExpertResponse,
  CaptureStudentResponse,
  Company,
  CompanyNameModel,
  EmailModel,
  Expert,
  IsEmailTakenResponse,
  Members,
  RegisterResponse,
  Student,
} from 'src/app/auth/model/register.model';
import {
  ForgotPasswordModel,
  LoginUser,
  LoginUserResponse,
} from 'src/app/auth/model/login.model';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { ApplicationRoutes } from 'src/app/configs/app-route.config';
import { AuthStore } from './auth.store';
import { ContextualConfigService } from '../../services/contextual-config.service';
import { ErrorMessage } from 'src/app/core/model/api-error';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ProfileImageService } from '../profile-image-state/profile-image.service';
import { AppApiConfiguration as apiOf } from 'src/app/configs/app-api.config';
import { resetStores } from '@datorama/akita';
// import { ToastService } from '../../services/toast.service';
import { ContactUsModel } from 'src/app/shared/models/contactus.model';
import { environment } from 'src/environments/environment';
import { EveryEngUserHubService } from '../../services/everyeng-user-hub.service';
import { ResetPasswordModel } from 'src/app/auth/model/reset-password.model';
import { VerifyAccountModel } from 'src/app/auth/model/verify-account.model';
import { GlobalObjectService } from 'src/app/shared/services/global-object.service';
// import { PopupLoadingComponent } from 'src/app/shared/components/popups/popup-loading/popup-loading.component';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { MemberProfileInfoService } from 'src/app/pages/profile/state/member-profile-info/member-profile-info.service';
import { ToastService } from '../../services/toast.service';
// import { PopupErrorComponent } from 'src/app/shared/components/popups/popup-error/popup-error.component';
// import { PopupSuccessComponent } from 'src/app/shared/components/popups/popup-success/popup-success.component';
@Injectable({ providedIn: 'root' })
export class  AuthService {
  private observe!: {
    observe: 'body';
  };

  appRoutes = ApplicationRoutes;

  constructor(
    private authStore: AuthStore,
    private http: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private configService: ContextualConfigService,
    private memberProfileInfoService: MemberProfileInfoService,
    private toastService: ToastService,
    private everyEngUserHubService: EveryEngUserHubService,
    private globalObject: GlobalObjectService,
    private dialogService: DialogService
  ) {}

  forgotPasswordEmailSentObservable$: Subject<boolean> = new Subject<boolean>();
  resetPasswordObservable$: Subject<boolean> = new Subject<boolean>();
  verifyAccountObservable$: Subject<boolean> = new Subject<boolean>();
  resendVerifyAccountObservable$: Subject<boolean> = new Subject<boolean>();

  async updateUIState(uiState: any) {
    this.authStore.update((state) => {
      return {
        ...state,
        ui: { ...state.ui, ...uiState },
      };
    });
  }

  async loginUser(model: LoginUser) {
    // const dialogRefLoading: DialogRef = this.dialogService
    // .open({
    //   content: PopupLoadingComponent
    // });
    // const loadingInfo = dialogRefLoading.content.instance as PopupLoadingComponent;
    // loadingInfo.writeUp = `Please wait while we safely login you through your account !`
    this.authStore.setLoading(true);
    try {
      await this.http
        .post<LoginUserResponse>(apiOf.auth.login, { ...model }, this.observe)
        .pipe(
          tap((data: LoginUserResponse) => {
            this.toastService.success(`You are successfully logged in.`)
            setTimeout(() => {
              // dialogRefLoading.close()
              // const dialogRefSuccess: DialogRef = this.dialogService
              // .open({
              //   content: PopupSuccessComponent
              // });
              // const successInfo = dialogRefSuccess.content.instance as PopupSuccessComponent;
              // successInfo.headerTitle = `You are successfully logged in.`
              // successInfo.buttonText = `Close`
              // setTimeout(() => {
              //   dialogRefSuccess.close()
                this.handleLogedInUserResponse(data);
                this.everyEngUserHubService.disConnect()
                this.authStore.setLoading(false);
              },1500)
            // },1000)
          }),
          catchError((loginError: ErrorMessage) => {
            // setTimeout(() => {
            //   dialogRefLoading.close()
              this.authStore.setError(loginError);
              loginError?.errors.map((err) => this.toastService.error(err));
            //   const dialogRefError: DialogRef = this.dialogService
            //   .open({
            //     content: PopupErrorComponent
            //   });
            //   const ErrorInfo = dialogRefError.content.instance as PopupErrorComponent;
            //   ErrorInfo.headerTitle = loginError.title
            //   ErrorInfo.errorList = loginError.errors
            //   ErrorInfo.buttonText = `Go back to Login`
            // },1000)
            return throwError(loginError);
          })
        )
        .toPromise();
    } catch (error) {}
    this.authStore.setLoading(false);
  }

  async refreshTokenValidate(){
    const authUser = this.authStore.getValue().__everyeng__eng_auth;
    if (!authUser) {
      this.router.navigate([ApplicationRoutes.auth.login], {
        queryParams: {
          redirectUrl: this.router.url,
          returnUrl: this.globalObject.getLocation()?.pathname,
        },
      });
    };

    if (authUser.refreshToken) {
      // const dialogRefLoading: DialogRef = this.dialogService
      // .open({
      //   content: PopupLoadingComponent
      // });
      // const loadingInfo = dialogRefLoading.content.instance as PopupLoadingComponent;
      // loadingInfo.writeUp = `Please wait while we are validate refresh token !`
      this.authStore.setLoading(true);
      try {
        await this.http
          .post<LoginUserResponse>(apiOf.auth.validateRefreshToken, { "refreshToken": authUser.refreshToken }, this.observe)
          .pipe(
            tap((data: LoginUserResponse) => {
              // setTimeout(() => {
              //   dialogRefLoading.close()
              //   const dialogRefSuccess: DialogRef = this.dialogService
              //   .open({
              //     content: PopupSuccessComponent
              //   });
              //   const successInfo = dialogRefSuccess.content.instance as PopupSuccessComponent;
              //   successInfo.headerTitle = `Your refresh token validate successfully.`
              //   // successInfo.buttonText = `Close`
              //   setTimeout(() => {
              //     dialogRefSuccess.close()
                  this.handleLogedInUserResponse(data);
                  this.everyEngUserHubService.disConnect()
                  this.authStore.setLoading(false);
              //   },1500)
              // },1000)
            }),
            catchError((loginError: ErrorMessage) => {
              // setTimeout(() => {
              //   dialogRefLoading.close()
              loginError?.errors.map((err) => this.toastService.error(err));
              this.authStore.setError(loginError);
                this.router.navigate([ApplicationRoutes.auth.login], {
                  queryParams: {
                    redirectUrl: this.router.url,
                    returnUrl: this.globalObject.getLocation()?.pathname,
                  },
                });
              // },1000)
              return throwError(loginError);
            })
          )
          .toPromise();
      } catch (error) {}
      this.authStore.setLoading(false);
    }else{
      this.router.navigate([ApplicationRoutes.auth.login], {
        queryParams: {
          redirectUrl: this.router.url,
          returnUrl: this.globalObject.getLocation()?.pathname,
        },
      });
    }
  }

  async captureExpertDetails(expertDetails: Expert) {
    this.authStore.setLoading(true);
    try {
      await this.http
        .post<CaptureExpertResponse>(
          apiOf.auth.captureExpert,
          { ...expertDetails },
          this.observe
        )
        .pipe(
          tap((data: CaptureExpertResponse) => {
            //take to cogratulatons page
            this.router.navigate([this.appRoutes.auth.welcome], {
              state: {
                name: `${expertDetails.firstName} ${expertDetails.lastName}`,
              },
            });
          }),
          catchError((loginError: ErrorMessage) => {
            loginError?.errors.map((err) => this.toastService.error(err));
            this.authStore.setError(loginError);
            return throwError(loginError);
          })
        )
        .toPromise();
    } catch (error) {}
    this.authStore.setLoading(false);
  }

  async captureStudentDetails(studentDetails: Student) {
    this.authStore.setLoading(true);
    try {
      await this.http
        .post<CaptureStudentResponse>(
          apiOf.auth.captureStudent,
          { ...studentDetails },
          this.observe
        )
        .pipe(
          tap((data: CaptureStudentResponse) => {
            //take to cogratulatons page
            this.router.navigate([this.appRoutes.auth.welcome], {
              state: {
                name: `${studentDetails.firstName} ${studentDetails.lastName}`,
              },
            });
          }),
          catchError((loginError: ErrorMessage) => {
            loginError?.errors.map((err) => this.toastService.error(err));
            this.authStore.setError(loginError);
            return throwError(loginError);
          })
        )
        .toPromise();
    } catch (error) {}
    this.authStore.setLoading(false);
  }

  async captureCompanyDetails(companyDetails: Company) {
    this.authStore.setLoading(true);
    try {
      await this.http
        .post<CaptureCompanyResponse>(
          apiOf.auth.captureCompany,
          { ...companyDetails },
          this.observe
        )
        .pipe(
          tap((data: CaptureCompanyResponse) => {
            //take to cogratulatons page
            this.router.navigate([this.appRoutes.auth.welcome], {
              state: {
                name: companyDetails.companyName,
              },
            });
          }),
          catchError((loginError: ErrorMessage) => {
            loginError?.errors.map((err) => this.toastService.error(err));
            this.authStore.setError(loginError);
            return throwError(loginError);
          })
        )
        .toPromise();
    } catch (error) {}
    this.authStore.setLoading(false);
  }

  async resetAuthStore() {
    // Reset all stores
    resetStores();
  }

  async clearError() {
    this.authStore.setError(null);
  }

  async registerDetails(registerDetails: Members) {
    this.authStore.setLoading(true);
    try {
      await this.http
        .post<RegisterResponse>(
          apiOf.auth.register,
          { ...registerDetails },
          this.observe
        )
        .pipe(
          tap((data: RegisterResponse) => {
            //take to cogratulatons page
            this.router.navigate([this.appRoutes.auth.welcome], {
              state: {
                name: registerDetails.emailId,
                password:registerDetails.password,
                confirmpassword:registerDetails.confirmPassword,
              },
            });
          }),
          catchError((loginError: ErrorMessage) => {
            loginError?.errors.map((err) => this.toastService.error(err));
            this.authStore.setError(loginError);
            return throwError(loginError);
          })
        )
        .toPromise();
    } catch (error) {}
    this.authStore.setLoading(false);
  }

  async isEmailTaken(emailModel: EmailModel) {
    this.authStore.setLoading(true);
    try {
      await this.http
        .post<IsEmailTakenResponse>(
          apiOf.auth.isEmailTaken,
          emailModel,
          this.observe
        )
        .pipe(
          tap((data: IsEmailTakenResponse) => {
            this.authStore.setLoading(false);
            if (!data.isEmailTaken) {
              this.updateUIState({
                currentStep: 2,
                email: emailModel.userEmail,
                createUserToken: data.createUserToken,
              });
            } else {
              this.toastService.error('Email is already taken.')
              this.authStore.setError({ title: 'Email is already taken.' });
            }
          }),
          catchError((loginError: ErrorMessage) => {
            loginError?.errors.map((err) => this.toastService.error(err));
            this.authStore.setError(loginError);
            return throwError(loginError);
          })
        )
        .toPromise();
    } catch (error) {}
    this.authStore.setLoading(false);
  }

  async isNewCompanyNameUnique(companyName: CompanyNameModel) {
    this.authStore.setLoading(true);
    try {
      await this.http
        .get<any>(`${apiOf.company.isNewCompanyNameUnique}?companyName=${companyName.companyName}`)
        .pipe(
          tap((data: any) => {
            this.authStore.setLoading(false);
            if (data) {
            } else {
              this.toastService.error('Company Name is already taken.')
              this.authStore.setError({ title: 'Company Name is already taken.' });
            }
          }),
          catchError((loginError: ErrorMessage) => {
            this.authStore.setError(loginError);
            return throwError(loginError);
          })
        )
        .toPromise();
    } catch (error) {}
    this.authStore.setLoading(false);
  }

  async logoutUser(isAutoLogout: boolean = false) {
    this.resetAuthStore();
    if (!isAutoLogout) this.router.navigate([this.appRoutes.appRoot]).then(()=>{
      if(this.globalObject.getLocation()?.pathname === this.appRoutes.appRoot){
        window.location.reload();
      }
    });
  }

  async forgotPassword(emailModel: ForgotPasswordModel): Promise<any> {
    this.authStore.setLoading(true);
    try {
      await this.http
        .post(apiOf.auth.forgotPassword, emailModel, this.observe)
        .pipe(
          tap((res: any) => {
            this.authStore.setLoading(false);
            this.forgotPasswordEmailSentObservable$.next(true);
          }),
          catchError((error: ErrorMessage) => {
            error?.errors.map((err) => this.toastService.error(err));
            this.authStore.setError(error);
            return throwError(error);
          })
        )
        .toPromise();
    } catch (error) {}
    this.authStore.setLoading(false);
  }
  
  async unsubscribe(emailModel: any): Promise<any> {
    // const dialogRefLoading: DialogRef = this.dialogService
    // .open({
    //   content: PopupLoadingComponent
    // });
    // const loadingInfo = dialogRefLoading.content.instance as PopupLoadingComponent;
    // loadingInfo.writeUp = `Please wait while we are complete your request!`
    try {
      await this.http
        .post(`${apiOf.auth.unsubscribe}?emailId=${emailModel}`, {}, this.observe)
        .pipe(
          tap((res: any) => {
            // setTimeout(() => {
            //   dialogRefLoading.close()
              this.router.navigate(['/'])
            // },1000);
          }),
          catchError((error: ErrorMessage) => {
            error?.errors.map((err) => this.toastService.error(err));
            // setTimeout(() => {
            //   dialogRefLoading.close()
            //   const dialogRefError: DialogRef = this.dialogService
            //   .open({
            //     content: PopupErrorComponent
            //   });
            //   const ErrorInfo = dialogRefError.content.instance as PopupErrorComponent;
            //   ErrorInfo.headerTitle = error.title
            //   ErrorInfo.errorList = error.errors
            //   ErrorInfo.buttonText = `Go back`
            // },1000);
            return throwError(error);
          })
        )
        .toPromise();
    } catch (error) {}
    this.authStore.setLoading(false);
  }

  async resetPassword(resetPasswordModel: ResetPasswordModel): Promise<any> {
    this.authStore.setLoading(true);
    try {
      await this.http
        .post(apiOf.auth.resetPassword, resetPasswordModel, this.observe)
        .pipe(
          tap((res: any) => {
            this.authStore.setLoading(false);
            this.resetPasswordObservable$.next(true);
          }),
          catchError((error: ErrorMessage) => {
            if (!error || !error.errors) {
              error = {
                errors: [
                  'Either user does not exist or reset token is invalid. Please contact EveryEng.',
                ],
                detail: '',
                instance: null,
                status: 400,
                title: 'Error while resetting password',
                type: '',
              };
            }
            this.authStore.setError(error);
            return throwError(error);
          })
        )
        .toPromise();
    } catch (error) {}
    this.authStore.setLoading(false);
  }

  async verifyAccount(verifyAccountModel: VerifyAccountModel): Promise<any> {
    this.authStore.setLoading(true);
    try {
      await this.http
        .post(apiOf.auth.verifyAccount, verifyAccountModel, {
          responseType: 'text',
        })
        .pipe(
          tap((res: any) => {
            this.authStore.setLoading(false);
            this.verifyAccountObservable$.next(true);
          }),
          catchError((error: ErrorMessage) => {
            if (!error || !error.errors) {
              error = {
                errors: [
                  'Either user does not exist or verify token is invalid.',
                ],
                detail: '',
                instance: null,
                status: 400,
                title: 'Error while resetting password',
                type: '',
              };
            }
            error?.errors.map((err) => this.toastService.error(err));
            this.authStore.setError(error);
            this.verifyAccountObservable$.next(false);
            return throwError(error);
          })
        )
        .toPromise();
    } catch (error) {}
    this.authStore.setLoading(false);
  }

  async resendVerifyAccountEmail(
    verifyAccountModel: VerifyAccountModel
  ): Promise<any> {
    this.authStore.setLoading(true);
    try {
      await this.http
        .post(
          apiOf.auth.resendVerifyAccountEmail,
          verifyAccountModel,
          this.observe
        )
        .pipe(
          tap((res: any) => {
            this.authStore.setLoading(false);
            this.resendVerifyAccountObservable$.next(true);
          }),
          catchError((error: ErrorMessage) => {
            if (!error || !error.errors) {
              error = {
                errors: ['User does not exist.'],
                detail: '',
                instance: null,
                status: 400,
                title: 'Error while resetting password',
                type: '',
              };
            }
            error?.errors.map((err) => this.toastService.error(err));
            this.authStore.setError(error);
            return throwError(error);
          })
        )
        .toPromise();
    } catch (error) {}
    this.authStore.setLoading(false);
  }

  handleLogedInUserResponse(
    response: LoginUserResponse,
    isRedirect: boolean = true
  ) {
    this.authStore.update((state) => {
      return {
        ...state,
        __everyeng__eng_auth: { ...state.__everyeng__eng_auth, ...response },
      };
    });

    this.memberProfileInfoService.getMemberProfile('?source=profile');

    if (isRedirect) {
      let redirectUrl: string =
        this.activatedRoute.snapshot.queryParams['redirectUrl'];

      if (!redirectUrl || redirectUrl.indexOf('auth') > -1) {
        redirectUrl = this.appRoutes.dashboard?.my || '';
      }

      if (
        redirectUrl &&
        redirectUrl.startsWith(environment.everyengPaymentLink)
      ) {
        redirectUrl = redirectUrl + `&token=${response.token}`;
        window.location.href = redirectUrl;
      } else {
        this.router.navigateByUrl(redirectUrl);
      }
    }
  }

  tryAutoLogin() {
    const authUser = this.authStore.getValue().__everyeng__eng_auth;
    if (!authUser) return;

    if (authUser.token) {
      const helper = new JwtHelperService();
      const isExpired = helper.isTokenExpired(authUser.token);
      if (isExpired) this.logoutUser(true);
      else {
        this.memberProfileInfoService.getMemberProfile('?source=profile');
      }
    }
  }

  isLoggedInUser(userId: any): Observable<any> {
    return new Observable((observer) => {
      const authState = this.authStore.getValue().__everyeng__eng_auth;
      observer.next(
        !!(authState && authState.token && authState.profileId == userId)
      );
    });
  }

  async isRefferalCodeValidOrNot(referalCode: any) {
    this.authStore.setLoading(true);
    let isInValid;
    const apiUrl = `${apiOf.auth.verifyReferralCode}?referralCode=${referalCode}`;
    try {
      await this.http
        .post(apiUrl, this.observe)
        .pipe(
          tap((res: any) => {
            this.authStore.setLoading(false);
            isInValid = false;
          }),
          catchError((error: any): any => {
            error?.errors.map((err: any) => this.toastService.error(err));
            this.authStore.setError(error);
            isInValid = true;
            return throwError(error);
          })
        )
        .toPromise();
    } catch (error) {}
    this.authStore.setLoading(false);
    return isInValid;
  }
  get isUserLoggedIn$(): Observable<boolean> {
    return new Observable((observer) => {
      const authState = this.authStore.getValue().__everyeng__eng_auth;
      observer.next(!!(authState && authState.token));
    });
  }

  async newsLetter(model: any): Promise<any> {
    try {
      await this.http
        .post<any>(apiOf.communication.newsLetter, model, this.observe)
        .pipe(
          tap(() => {
            this.toastService.success(
              'Newsletter subscribed successfully',
            );
          }),
          catchError((error: ErrorMessage) => {
            error?.errors.map((err) => this.toastService.error(err));
            return throwError(error);
          })
        )
        .toPromise();
    } catch (error) {}
  }

  bookDemo(model: any){
    return this.http.post(apiOf.home.bookDemo, model);
  }
  
  contactUs(model: ContactUsModel){
    return this.http.post(apiOf.home.userEnquiry, model);
  }

  getShareUrl(url: any){
    return this.http.get(`${apiOf.auth.getShareUrl}?${url}`);
  }

  userCourseRequest(model: any){
    return this.http.post(apiOf.auth.courseRequest, model);
  }
}
