import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { MemberProfileInfoModel } from '../../models/member-profile-info.model';

export interface MemberProfileInfoState {
    profileInfo: MemberProfileInfoModel | null
}

export function createInitialState(): MemberProfileInfoState {
  return {
    profileInfo: null
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'memberProfile', resettable: true })
export class MemberProfileInfoStore extends Store<MemberProfileInfoState> {
  constructor() {
    super(createInitialState());
  }
}
